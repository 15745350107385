<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Danh tài khoản bị khóa</h6>
        </template>
        <b-row>
          <b-col class="col-md-4">
            <b-form-group label="Số điện thoại">
              <b-form-input
                  v-model="filter.phone"
                  placeholder="Số điện thoại"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Thời gian bắt đầu(*)">
              <b-form-input
                  :id="`type-date`"
                  :type="`date`"
                  v-model="filter.date_from"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Thời gian kết thúc(*)">
              <b-form-input
                  :id="`type-date`"
                  :type="`date`"
                  v-model="filter.date_to"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col class="col mb-2">
            <b-button class="mr-2" variant="outline-info" @click="search">Tìm kiếm</b-button>
            <b-button class="mr-2" variant="outline-danger" @click="resetSearch">Xóa bộ lọc</b-button>
            <b-button class="mr-2" variant="outline-success" @click="$bvModal.show(exportId);">Xuất Excel</b-button>
          </b-col>
        </b-row>

        <hr />
        <div v-if="paginate.total > 0"><span>Tổng số: </span><strong>{{ numberFormat(paginate.total) }}</strong></div>
        <b-table
          :items="items"
          :fields="fields"
          ref="table"
          striped
          hover
          responsive
          caption-top
        >
          <template #cell(block_type)="data">
            {{ data.item.block_type }}
          </template>
          <template #cell(reason)="data">
            {{ data.item.reason ?? "Khóa tự động" }}
          </template>
          <template #cell(created_by)="data">
            {{ data.item.created_by ?? "Khóa tự động" }}
          </template>
          <template #cell(balance)="data">
            {{ formatMoneyVn(data.item.balance) }}
          </template>
          <template #cell(created_at)="data">
            {{ formatDate(data.item.created_at) }}
          </template>
          <template #cell(options)="data" class="text-right">
            <b-button-group size="sm">
              <b-button class="btn btn-sm btn-outline-success" @click="showModal(data.item.phone, data.item.lock_type)">Mở khóa</b-button>
            </b-button-group>
          </template>
        </b-table>
        <div class="mt-3">
          <b-pagination-nav
            v-model="currentPage"
            :link-gen="createLinkPage"
            :number-of-pages="paginate.totalPage"
            use-router
            first-number
            last-number
            align="right"
          ></b-pagination-nav>
        </div>
      </b-card>
    </b-card-group>

    <b-modal id="my-modal" hide-footer ref="myModal" :title="`Mở khóa user: ${this.unlockData.phone}`">
      <b-form class="col-12">
        <b-form-group label="Lý do (*)">
          <b-form-textarea
              v-model="unlockData.description"
              placeholder="Nhập lý do"
              rows="5"
          ></b-form-textarea>
        </b-form-group>
        <b-row style="float: right">
          <b-button type="submit" variant="primary" v-on:click="unlockUser()"
          >Mở khóa
          </b-button>
        </b-row>
      </b-form>
    </b-modal>

    <export-modal :modalId="exportId" @exportData="exportData"></export-modal>
    <alert-dialogue ref="confirmDialogue"></alert-dialogue>
  </div>
</template>
<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import router from "@/router";
import ExportModal from "@/view/pages/components/ExportModal.vue";
import AlertDialogue from "@/view/pages/components/AlertModal.vue";

const CmsRepository = RepositoryFactory.get("cms");
const AccountRepository = RepositoryFactory.get("account");

Vue.use(Notifications);
export default {
  mixins: [Common],
  components: {AlertDialogue, ExportModal},
  data() {
    return {
      exportId: "export_lock_account",
      items: [],
      item: {
        name: null,
        time_frame: null,
        message: null,
        status: null,
        params: {},
      },
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      filter: {
        title: null,
        description: null,
        status: null,
        date_from: null,
        date_to: null,
        limit: 10,
        exportDesc: null
      },
      fields: [
        { phone: "Số điện thoại" },
        { email: "Email" },
        { name: "Họ tên" },
        { block_type: "Loại" },
        { reason: "Lý do" },
        { created_by: "Người khóa" },
        { balance: "Số dư" },
        { created_at: "Thời gian khóa" },
        { options: "Hành động" },
      ],
      unlockData: {
        phone: null,
        block_type: null,
        description: null
      }
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh sách hành động", route: "tools/users/block" },
    ]);
  },
  methods: {
    async exportData(description = null) {
      this.$bus.$emit("show-loading", true);
      let params = this.convert(this.filter);
      params.export_desc = description
      this.$bvModal.hide(this.exportId);

      let response = await AccountRepository.exportData(params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;
      if (body.error_code) {
        this.notifyAlert("warn", response.data.message);
        return true;
      }

      this.$bus.$emit("show-loading", false);
      let link = window.location.origin + "/#/reconcile/export-data";
      let cfm = await this.$refs.confirmDialogue.show({
        title: "Download File",
        message: "File đang được xử lý, bạn có thể tải xuống sau ít phút <a href='" + link + "' target='_blank'>TẠI ĐÂY</a>",
        okButton: "Ok",
      });

      if (!cfm) {
        self.$bus.$emit("show-loading", false);
        return (self.errorMessage = "Có lỗi khi lấy thông tin");
      }
    },
    convert(params) {
      for (let key in params) {
        if (params[key] == "" || params[key] == null) {
          delete params[key];
        }
      }
      return params;
    },
    search() {
      this.filter.page = 1;
      router.push({ path: "/tools/users/block", query: this.convert(this.filter) }).catch(() => {});

      if (this.currentPage !== 1) {
        this.currentPage = 1;
        return;
      }

      this.getList(this.currentPage);
    },
    resetSearch() {
      this.filter.phone = null;
      this.filter.date_from = null;
      this.filter.date_to = null;
      this.search();
    },
    getList(page = 1) {
      this.items = [];
      let params = { ...this.filter };
      params = this.convert(params);
      params.page = page;
      this.$bus.$emit("show-loading", true);
      CmsRepository.usersLock(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
            return;
          }

          this.items = response.data.data.data;
          this.paginate.total = response.data.data.total;
          this.paginate.totalPage = response.data.data.last_page;
          this.paginate.currentPage = response.data.data.current_page;
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
    showModal(phone, type) {
      this.unlockData.description = null;
      this.unlockData.phone = phone;
      this.unlockData.block_type = type;

      this.$refs["myModal"].show();
    },
    unlockUser() {
      let conf = confirm("Bạn có chắc chắn mở khóa tài khoản này không?");

      if (conf === false) {
        return true;
      }

      this.$bus.$emit("show-loading", true);
      let params = this.unlockData;

      if (!params.description) {
        this.notifyAlert("warn", "Lý do không được để trống");
        this.$bus.$emit("show-loading", false);
        return false;
      }

      params = this.convert(params);
      this.$refs["myModal"].hide();

      CmsRepository.unLockUser(params)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
            return;
          }

          this.getList();
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
  },
  created() {
    this.query = this.$route.query;
    this.getList();
  },
  watch: {
    currentPage() {
      this.getList(this.currentPage);
    },
  },
};
</script>
